import fetch from 'cross-fetch';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

import config from '../config';
import fragmentTypes from './schema/fragmentTypes.json';

const removeTypenameLink = removeTypenameFromVariables();
loadDevMessages();
loadErrorMessages();
const httpLink = createHttpLink({
  uri: config.GRAPHQL_SERVER_URL,
  fetch,
  fetchOptions: {
    credentials: 'include',
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([removeTypenameLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
    typePolicies: {
      InteractionDetails: {
        merge: true,
      },
      EWebinar: {
        fields: {
          themeSettings: {
            merge: true,
          },
          scheduleSettings: {
            merge: true,
          },
          registrationPageSettings: {
            merge: true,
          },
          viewerRoomSettings: {
            merge: true,
          },
        },
      },
      EWebinarSet: {
        fields: {
          setSettings: {
            merge: true,
          },
          urls: {
            merge: true,
          },
        },
      },
      Team: {
        fields: {
          themeSettings: {
            merge: true,
          },
        },
      },
      Statistic: {
        fields: {
          value: {
            merge: (existing, incoming) => {
              if (existing && incoming === 0) {
                // If the incoming value is 0, but the existing value is not 0, the incoming value is from dummy statistic, let ignore it
                return existing;
              }
              return incoming;
            },
          },
        },
      },
    },
  }),
});
